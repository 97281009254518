import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

import { BrandingBarComponent } from './branding-bar.component';

@NgModule({
  declarations: [BrandingBarComponent],
  exports: [BrandingBarComponent],
  imports: [CommonModule, MatButtonModule],
})
export class BrandingBarModule {}
