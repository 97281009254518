import { Injectable } from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavService {

  public body = document.getElementsByTagName('body')[0];

  constructor(
    public router: Router
  ) { }

  public navigateTo(routeName = ''): void {
  // const body = document.getElementsByTagName('body')[0];
    this.body.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    this.router.navigate([routeName]);
}
// I forget the syntax to navigate to a router link in code, but it goes here
}
