<footer>
  <div class="footer-wrapper">
  <div class="footer-content">
    <img src="../../../../assets/images/uiowa-wordmark.png" alt="The University of Iowa" />
    <a class="site-name-link" (click)="nav.navigateTo('overview')">
      <h2 class="site-name">
        Premature Birth Support
      </h2>
    </a>
    <strong class="parent-site-name">
      <a href="https://uihc.org/"> University of Iowa Hospitals & Clinics </a>
    </strong>
    <div class="region region-footer-first">
      <div id="block" class="block block-block-content">
        <p>
          200 Hawkins Drive<br />
          Iowa City, IA 52242
        </p>
      </div>
    </div>
    <div class="socket">
      <ul class="socket__menu">
        <li>© 2022 The University of Iowa</li>
        <li>
          <a href="https://uiowa.edu/privacy">Privacy Notice</a>
        </li>
        <li>
          <a
            href="https://opsmanual.uiowa.edu/community-policies/nondiscrimination-statement"
            >Nondiscrimination Statement</a
          >
        </li>
        <li>
          <a href="https://uiowa.edu/accessibility">Accessibility</a>
        </li>
        <li>
          <a href="https://nativeamericancouncil.org.uiowa.edu"
            >UI Indigenous Land Acknowledgement</a
          >
        </li>
      </ul>
    </div>
  </div>
  </div>
</footer>
