<app-nav-menu></app-nav-menu>
<app-footer></app-footer>
<div
  *ngIf="showSpinner() === true"
  style="display: flex; flex-direction: column; justify-content: center"
  id="progress-outer"
  [class]="showSpinner() === true ? 'show-spinner' : 'hide-spinner'"
>
  <div style="display: flex; flex-direction: row; justify-content: center">
    <mat-spinner></mat-spinner>
  </div>
</div>
