import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'premature-birth';

  public spinnerVisible = true;

  ngOnInit(): void {
    window.setTimeout(() => {
      this.spinnerVisible = false;
    }, 1500);
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  public showSpinner(): boolean {
    return this.spinnerVisible;
  }
}
