import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-branding-bar',
  templateUrl: './branding-bar.component.html',
  styleUrls: ['./branding-bar.component.scss'],
})
export class BrandingBarComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }
}
