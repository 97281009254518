<mat-sidenav-container
  class="sidenav-container"
  routerLinkActive="activebutton"
  hasBackdrop="false"
>
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport
    role="navigation"
    mode="side"
  >
    <mat-toolbar aria-level="1">
      <button
        class="menu-toggle-button"
        type="button"
        aria-label="Toggle Navigation Menu"
        mat-icon-button
        (click)="drawer.toggle()"
      >
        <mat-icon>menu</mat-icon>
        <h1 class="offscreen">Premature Birth Support</h1>
      </button>
    </mat-toolbar>
    <mat-nav-list>
      <a
        tabindex="0"
        (keydown.enter)="visit('/overview')"
        mat-list-item
        (click)="visit('/overview')"
        routerLinkActive="activebutton"
        >Overview</a
      >
      <a
        tabindex="0"
        (keydown.enter)="visit('/steps')"
        mat-list-item
        (click)="visit('/steps')"
        routerLinkActive="activebutton"
      >Steps</a
      >
      <a
        tabindex="0"
        (keydown.enter)="visit('/equipment')"
        mat-list-item
        (click)="visit('/equipment')"
        routerLinkActive="activebutton"
        >Equipment</a
      >
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <app-branding-bar></app-branding-bar>
    <mat-toolbar
      class="border-bottom"
      role="heading"
      color="primary"
      routerLinkActive="activebutton"
      aria-level="1"
    >
      <button
        type="button"
        class="menu-toggle-button"
        aria-label="Toggle Navigation Menu"
        mat-icon-button
        (click)="drawer.toggle()"
      >
        <mat-icon>menu</mat-icon>
      </button>
      <a (click)="nav.navigateTo('overview')">
        <h1 class="margin-left">
          Premature Birth Support
        </h1>
      </a>
    </mat-toolbar>
    <div class="wrapper">
      <router-outlet></router-outlet>
    </div>
    <!-- Add Content Here -->
  </mat-sidenav-content>
</mat-sidenav-container>
